import React from "react"
import { Responsive } from "semantic-ui-react"

import {
  PlateSizeCodes,
  PlateDesignFormats,
  DefaultCombination,
  PlateSizes,
} from "./Constant"
import theme from "@styles/theme"
import {
  PlateDesignModel,
  CaptionPosition,
  ProductSize,
  VehicleType,
  VehicleTypesSetting,
  AuthorizedDealers,
  TransactionType,
  TransactionTypesSetting,
} from "@models/ProductModels"
import { KenticoChoice, KenticoImage } from "./KontentTypes"
import { KenticoAuthorizedDealers } from "@mappers/ProductModels/mapper"
import { BasketLineResponseModel } from "@models/Basket/BasketLineResponseModel"
import { Address } from "@models/Checkout/Checkout"
import { BasketPlateCaptionResponseModel } from "@models/Basket/BasketCaptionResponseModel"
import { BasketPlateResponseModel } from "@models/Basket/BasketPlateResponseModel"
import { OrderConfirmationTransactionEntity } from "@models/OrderConfirmation/OrderConfirmationDataModel"
import { BasketPlateSizeResponseModel } from "@models/Basket/BasketPlateSizeResponseModel"
import { GiftingType } from "@components/Mobile/GiftingSelection/GiftingType"

export const getInternalUrl = (url: string, queryString?: string) => {
  const internalUrl = url.startsWith("/") ? url : "/" + url
  return queryString ? internalUrl + "?" + queryString : internalUrl
}

export const getWidth = () => {
  const isSSR = typeof window === "undefined"
  const width = isSSR
    ? (Responsive.onlyTablet.minWidth as number)
    : window.innerWidth

  return width
}
export const getCaptionPosition = (
  caption: KenticoChoiceType
): CaptionPosition => {
  return {
    text: caption.name,
    value: caption.codename,
  }
}
export const getVehicleType = (type: KenticoChoice): VehicleType => {
  switch (type.codename) {
    case "car":
      return VehicleTypesSetting.Car
    case "motorbike":
      return VehicleTypesSetting.Motorbike
    default:
      return VehicleTypesSetting.Trailer
  }
}
export const getDealerOnly = (type: KenticoChoice): boolean => {
  switch (type.codename) {
    case "yes":
      return true
    default:
      return false
  }
}
export const getAuthorizedDealers = (
  dealers: KenticoAuthorizedDealers
): AuthorizedDealers => {
  const { title, link_message, description, dealer_contacts } = dealers
  const contacts = dealer_contacts.linked_items.map(item => {
    const {
      dealer_name,
      contact_name,
      phone,
      formatted_phone_number,
      dealer_id
    } = item.elements
    return {
      name: dealer_name.value,
      contact: contact_name.value,
      phone: phone.value,
      formattedPhone: formatted_phone_number.value,
      dealerId: dealer_id?.value
    }
  })
  return {
    title: title.value,
    linkText: link_message.value,
    description: description.value,
    dealers: contacts,
  }
}
export const checkIsEncodeURI = (str: string) => {
  return /\%/i.test(str)
}
export const encodeURIParameterIfNotEncoded = (str: string) => {
  const isEncoded = checkIsEncodeURI(str);
  if (isEncoded) {
    return str;
  } else {
    return encodeURIComponent(str);
  }
}
export const buildPlateImageUrlFromBasket = (
  apiUrlBase: string,
  plateDesign: BasketPlateResponseModel,
  formattedCombination: string,
  topCaption: BasketPlateCaptionResponseModel | undefined,
  bottomCaption: BasketPlateCaptionResponseModel | undefined
) => {
  let url = `${apiUrlBase}/image/${plateDesign.designId}/${formattedCombination}/${plateDesign.size.id}/${plateDesign.foregroundColorId}/max/500`
  let hasTopMessage = false
  if (topCaption !== undefined) {
    hasTopMessage = true
    const topMessage =
      "/?tcc=" +
      topCaption.captionColourId +
      "&tct=" +
      encodeURIComponent(topCaption.captionText)
    url = url + topMessage
  }
  if (bottomCaption !== undefined) {
    const appender = hasTopMessage ? "&" : "/?"
    const bottomMessage =
      appender +
      "bcc=" +
      bottomCaption.captionColourId +
      "&bct=" +
      encodeURIComponent(bottomCaption.captionText)
    url = url + bottomMessage
  }
  return url
}
export const convertKontentColorToHex = (color: string) => {
  if (color) {
    const hexCode = color.substring(1)
    const multiHexCode = hexCode.split("_")
    if (multiHexCode.length > 1) {
      return multiHexCode.map(c => {
        return `#${c}`
      })
    }
    return "#" + hexCode
  }
  return theme.brand.colors.white
}
export const getKenticoCode = (kenticoCode: string) => {
  const code = kenticoCode.substring(1)
  return code
}
type KenticoChoiceType = {
  name: string
  codename: string
}
export const getTextColor = (kenticoChoice: KenticoChoiceType) => {
  const name = kenticoChoice.name
  const code = kenticoChoice.codename
  const id = getKenticoCode(code)
  const indexStart = name.indexOf("[")
  const indexEnd = name.indexOf("]")
  const colorName = name.substring(0, indexStart).trim()
  const colorCode = name.substring(indexStart + 1, indexEnd).trim()
  return {
    id: +id,
    name: colorName,
    color: colorCode,
  }
}

export const getMessageColors = (kenticoChoices: KenticoChoiceType[]) => {
  return kenticoChoices.map(kc => getTextColor(kc))
}
export const mapPlateSizeFromTrnasaction = (
  plateSizeId: number,
  plateSizeName: string
): BasketPlateSizeResponseModel => {
  let plateSize
  switch (plateSizeName) {
    case "Graphic":
      plateSize = PlateSizes.Graphic
      break
    case "Slim":
      plateSize = PlateSizes.Slim
      break
    case "European":
      plateSize = PlateSizes.European
      break
    case "Motorbike":
      plateSize = PlateSizes.Motorbike
      break
    case "Large":
      plateSize = PlateSizes.Large
      break
    case "Japanese":
      plateSize = PlateSizes.Japanese
      break
    case "American":
      plateSize = PlateSizes.American
      break
    default:
      plateSize = PlateSizes.Regular
  }
  return {
    id: plateSizeId,
    name: plateSize.name,
    width_mm: plateSize.width,
    height_mm: plateSize.height,
  }
}

export const getPlateSize = (name: string, codename: string) => {
  const width = codename.substring(1, 4)
  const height = codename.substring(5)
  let plateSizeId = 0
  switch (name) {
    case "Graphic":
      plateSizeId = PlateSizeCodes.Graphic
      break
    case "Slim":
      plateSizeId = PlateSizeCodes.Slim
      break
    case "European":
      plateSizeId = PlateSizeCodes.European
      break
    case "Motorbike":
      plateSizeId = PlateSizeCodes.Motorbike
      break
    case "Large":
      plateSizeId = PlateSizeCodes.Large
      break
    case "Japanese":
      plateSizeId = PlateSizeCodes.Japanese
      break
    case "American":
      plateSizeId = PlateSizeCodes.American
      break
    default:
      plateSizeId = PlateSizeCodes.Regular
  }
  const result: ProductSize = {
    name: name,
    id: plateSizeId,
    width: +width,
    height: +height,
  }
  return result
}
//Input: array of name and codename (wxxxhxxx)
//Output: array of object with name, with and height
export const mapToPlateSizes = (plateSizes: KenticoChoice[]) => {
  let result: ProductSize[] = []
  plateSizes.forEach(ps => {
    const name = ps.name
    const dimension = ps.codename
    result.push(getPlateSize(name, dimension))
  })
  return result
}

export const getPlateColor = (name: string, codename: string) => {
  const colorCode = convertKontentColorToHex(codename)

  return {
    name: name,
    color: colorCode,
  }
}

export const getPlateFormat = (combination: string) => {
  const combinationNoWhiteSpace = combination.replace(/\s/g, "")
  const fixedPattern = /\b(^[a-zA-Z]{3}[0-9]{1,3})\b|\b(^[0-9]{1,3}[a-zA-Z]{3})$\b/
  if (fixedPattern.test(combinationNoWhiteSpace)) {
    return PlateDesignFormats.Fixed
  } else if (combinationNoWhiteSpace.length > 3) {
    return PlateDesignFormats.Flexi
  } else if (combinationNoWhiteSpace.length === 3) {
    return PlateDesignFormats.Premium3
  } else {
    return PlateDesignFormats.Premium2
  }
}

export const getPlateFormatExampleCombinations = (
  plateDesignFormat?: string
) => {
  switch (plateDesignFormat) {
    case "Fixed Format":
      return ["ABC123", "123ABC", "ABC12"]
    case "Flexi Format":
      return ["ABCD", "ABCDE", "ABCDEF"]
    case "Premium Format":
      return ["ABC", "AB"]
    default:
      return [""]
  }
}

export const buildPlateImageInfoFromBasket = (
  apiUrlBase: string,
  basket: BasketLineResponseModel,
  maxWidth?: number
) => {
  const width = maxWidth ? maxWidth : 750
  let frontUrl =
    apiUrlBase +
    `//image/${basket.frontPlate.designId}/${basket.combinationLayout}/${basket.frontPlate.size.id}/${basket.frontPlate.foregroundColorId}/max/${width}`
  let backUrl =
    apiUrlBase +
    `//image/${basket.backPlate.designId}/${basket.combinationLayout}/${basket.backPlate.size.id}/${basket.backPlate.foregroundColorId}/max/${width}`

  const topMessage = basket.topCaption
    ? `tcc=${basket.topCaption.captionColourId}&tct=${encodeURIComponent(
      basket.topCaption.captionText
    )}`
    : undefined
  const bottomMessage = basket.bottomCaption
    ? `bcc=${basket.bottomCaption.captionColourId}&bct=${encodeURIComponent(
      basket.bottomCaption.captionText
    )}`
    : undefined
  const frontWidth = getFrontPlateWidth(
    basket.frontPlate.size.width_mm,
    basket.backPlate.size.width_mm
  )
  const backWidth = getBackPlateWidth(
    basket.frontPlate.size.width_mm,
    basket.backPlate.size.width_mm
  )
  const message = topMessage
    ? bottomMessage
      ? `${topMessage}&${bottomMessage}`
      : topMessage
    : bottomMessage
      ? bottomMessage
      : undefined
  return {
    frontUrl: message ? `${frontUrl}/?${message}` : frontUrl,
    backUrl: message ? `${backUrl}/?${message}` : backUrl,
    frontWidth: frontWidth,
    backWith: backWidth,
  }
}

export const getFrontPlateWidth = (frontWidth: number, backWidth: number) => {
  if (frontWidth >= backWidth) {
    return "100%"
  } else {
    const ratio = frontWidth / backWidth
    const percent = ratio * 100
    return percent + "%"
  }
}

export const getBackPlateWidth = (frontWidth: number, backWidth: number) => {
  if (backWidth >= frontWidth) {
    return "100%"
  } else {
    const ratio = backWidth / frontWidth
    const percent = ratio * 100
    return percent + "%"
  }
}
export const buildPlateImageUrl = (
  apiUrlBase: string,
  plate: PlateDesignModel,
  formattedCombination: string,
  width: number
) => {
  const DEFAULT_MESSAGE = "ENTER A MESSAGE"
  let url =
    apiUrlBase +
    "//image/" +
    plate.plateDesignCode +
    "/" +
    formattedCombination +
    "/" +
    plate.plateSize.id +
    "/" +
    plate.textColor.id +
    "/max/" +
    width
  if (plate.captionPositon !== undefined) {
    let hasTopMessage = false
    if (plate.topMessageColor !== undefined) {
      hasTopMessage = true
      if (
        plate.topMessageText !== undefined &&
        plate.topMessageText.trim().length > 0
      ) {
        const topMessage =
          "/?tcc=" +
          plate.topMessageColor.id +
          "&tct=" +
          encodeURIComponent(plate.topMessageText)
        url = url + topMessage
      } else {
        const topMessage =
          "/?tcc=" +
          plate.topMessageColor.id +
          "&tct=" +
          encodeURIComponent(DEFAULT_MESSAGE)
        url = url + topMessage
      }
    }
    if (plate.bottomMessageColor !== undefined) {
      if (
        plate.bottomMessageText !== undefined &&
        plate.bottomMessageText.trim().length > 0
      ) {
        const bottomMessage = hasTopMessage
          ? "&bcc=" +
          plate.bottomMessageColor.id +
          "&bct=" +
          encodeURIComponent(plate.bottomMessageText)
          : "/?bcc=" +
          plate.bottomMessageColor.id +
          "&bct=" +
          encodeURIComponent(plate.bottomMessageText)
        url = url + bottomMessage
      } else {
        const bottomMessage = hasTopMessage
          ? "&bcc=" +
          plate.bottomMessageColor.id +
          "&bct=" +
          encodeURIComponent(DEFAULT_MESSAGE)
          : "/?bcc=" +
          plate.bottomMessageColor.id +
          "&bct=" +
          encodeURIComponent(DEFAULT_MESSAGE)
        url = url + bottomMessage
      }
    }
  }
  return url
}
const mapKenticoImage = (image: KenticoImage) => {
  return {
    url: image.url,
    description: image.description === undefined ? "" : image.description,
  }
}

export const mapFirstKenticoImage = (images: KenticoImage[]) => {
  const firstImage = images[0]

  if (firstImage !== undefined) {
    return mapKenticoImage(firstImage)
  } else {
    throw new Error(
      "Kentico Image Missing. Please make sure the content is published and available."
    )
  }
}

export const mapKenticoImages = (images: KenticoImage[]) => {
  return images.map(img => {
    return mapKenticoImage(img)
  })
}

export const getDefaultCombination = (vehicleType: VehicleType) => {
  switch (vehicleType) {
    case VehicleTypesSetting.Trailer:
      return DefaultCombination.Trailer
    case VehicleTypesSetting.Motorbike:
      return DefaultCombination.Motorbike
    default:
      return DefaultCombination.Car
  }
}

export const isDefaultCombination = (
  formattedCombination: string,
  vehicleType: VehicleType
) => {
  const combination = formattedCombination.toString().split(" ").join("")
  const defaultCombination = getDefaultCombination(vehicleType)
  return combination === defaultCombination
}

export const getVehicleTypeById = (id: number) => {
  if (VehicleTypesSetting.Motorbike.id === id) {
    return VehicleTypesSetting.Motorbike
  } else if (VehicleTypesSetting.Trailer.id === id) {
    return VehicleTypesSetting.Trailer
  } else {
    return VehicleTypesSetting.Car
  }
}

export const getTransactionTypeById = (id: number) => {
  if (TransactionTypesSetting.New.id === id) {
    return TransactionTypesSetting.New
  } else if (TransactionTypesSetting.Redesign.id === id) {
    return TransactionTypesSetting.Redesign
  } else {
    return TransactionTypesSetting.Upgrade
  }
}

export const getVehicleTypeByQueryString = (vt: number | undefined) => {
  let vehicleType: VehicleType = VehicleTypesSetting.Car
  if (vt === 2) {
    vehicleType = VehicleTypesSetting.Trailer
  } else if (vt === 3) {
    vehicleType = VehicleTypesSetting.Motorbike
  }
  return vehicleType
}

export const getTransactionTypeByQueryString = (tt: number | undefined) => {
  let transactionType: TransactionType = TransactionTypesSetting.New
  if (tt === TransactionTypesSetting.Redesign.id) {
    transactionType = TransactionTypesSetting.Redesign
  } else if (tt === TransactionTypesSetting.Upgrade.id) {
    transactionType = TransactionTypesSetting.Upgrade
  }
  return transactionType
}
export const getCombinationByQueryString = (
  comb: string | undefined,
  vehicleType?: VehicleType
) => {
  if (comb !== undefined) {
    const combination = comb.toString()
    if (combination.trim() !== "") {
      return combination
    }
  }
  if (vehicleType) {
    return getDefaultCombination(vehicleType)
  }
  return ""
}

export const setEmptyIfUndefiendByQueryString = (
  str: string | undefined
) => {
  if (str !== undefined) {
    const cstr = str.toString()
    if (cstr.trim() !== "") {
      return cstr
    }
  }
  return ""
}



export const getIsGiftingByQueryString = (gift: number | undefined) => {
  if (gift === undefined) {
    return false
  }
  return gift === 1 ? true : false
}
export const getPlateDesignByQueryString = (
  plateDesign: string | undefined
) => {
  if (plateDesign !== undefined) {
    try {
      const designs = plateDesign.split(",")
      return {
        designCode: designs[0],
        textColor: designs[1],
        sizeId: designs[2],
      }
    } catch (err) {
      console.log(err)
      return undefined
    }
  }
  return undefined
}
export const getCleanCampaignByQueryString= (campaign: number | undefined) =>{
  if(campaign === undefined){
    return false
  }
  return campaign === 1 ? true : false
}

export const ConvertModelToQueryString = (data: any) => {
  let queryString: string[] = []
  for (var item in data) {
    const qs = `${item}=${data[item]}`
    queryString.push(qs)
  }
  return queryString.join("&")
}
export const getDesignIdByQueryString = (designId: number | undefined) => {
  if (designId === undefined) {
    return 0
  }
  return designId
}
export const roundTo = (n: number, digits: number) => {
  if (digits === undefined) {
    digits = 0
  }

  var multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  return Math.round(n) / multiplicator
}

export const formatAddressAsString = (address: Address) => {
  return `${address.building && address.building + ' '} ${address.unit && address.unit + ' '} ${address.street && address.street + ' '} ${address.suburb && address.suburb + ' '} ${address.city && address.city + ' '} ${address.postCode && address.postCode}`
}

export const formattedAddress = (address: Address, name?: string, companyName?: string) => {
  return (
    <div>
      {name && <span>{name}</span>}
      {name && <br />}
      {companyName && <span>{companyName}</span>}
      {companyName && <br />}
      {address.building && <span>{address.building}</span>}
      {address.building && <br />}
      {address.unit && <span>{address.unit}</span>}
      {address.unit && <br />}
      <span>{address.street}</span>
      <br />
      {address.suburb && <span>{address.suburb}</span>}
      {address.suburb && <br />}
      <span>
        {address.city} {address.postCode}
      </span>
    </div>
  )
}

export const formattedTransactionAddress = (
  entity: OrderConfirmationTransactionEntity
) => {
  return (
    <div>
      {entity.FullName && <span>{entity.FullName}</span>}
      {entity.FullName && <br />}
      {entity.AddressLine1 && <span>{entity.AddressLine1}</span>}
      {entity.AddressLine1 && <br />}
      {entity.AddressLine2 && <span>{entity.AddressLine2}</span>}
      {entity.AddressLine2 && <br />}
      {entity.AddressLine3 && <span>{entity.AddressLine3}</span>}
      <br />
      {entity.Suburb && <span>{entity.Suburb}</span>}
      {entity.Suburb && <br />}
      <span>
        {entity.City} {entity.PostCode}
      </span>
    </div>
  )
}

export const formattedGiftBoxAddress = (address: Address, name?: string, companyName?: string) => {
  return (
    <span>      
      {address.building && <span>{address.building}</span>}
      {address.building && ', '}
      {address.unit && <span>{address.unit}</span>}
      {address.unit && ', '}
      <span>{address.street} {', '}</span>
      
      {address.suburb && <span>{address.suburb}</span>}
      {address.suburb && ', '}
      <span>
        {address.city} {', '} {address.postCode}
      </span>
    </span>
  )
}
export const isOwnerSameAsApplicant = (
  owner: OrderConfirmationTransactionEntity,
  applicant: OrderConfirmationTransactionEntity
) => {
  return owner.FullName == applicant.FullName && owner.Email == applicant.Email
}
export const hash = (s: string) => {
  /* Simple hash function. */
  /* eslint-disable no-mixed-operators */

  var a = 1,
    c = 0,
    h,
    o
  if (s) {
    a = 0
    /*jshint plusplus:false bitwise:false*/
    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h)
      a = ((a << 6) & 268435455) + o + (o << 14)
      c = a & 266338304
      a = c !== 0 ? a ^ (c >> 21) : a
    }
  }

  /* eslint-enable no-mixed-operators */

  return String(a)
}

export const isBrowser = typeof window !== `undefined`

export const buildDefaultImageUrl = (apiUrl: string, combination: string) => {
  return `${apiUrl}/image/68/${combination}/4/4/max/750`
}

export const padLeadingZeros = (numInput: number, totalLength: number) => {
  var strNumber = numInput + "";
  while (strNumber.length < totalLength) strNumber = "0" + strNumber;
  return strNumber;
}

export const matchTagRegex = (input: string) =>  /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g.test(input);

export const htmlEncode = (input: string) => {
  return input
      .replace(/>/g, '%3E')
      .replace(/</g, '%3C');
}

export const isCheckoutCertain = (url: any) => {
  var host = url.substr(url.length - 15);
  if(host.includes("kiwiplates.nz")){
    return false
  }

  var noneCheckoutUrl = ["create", "select-a-style", "shopping-cart", "restyle"]
  var val = true
  noneCheckoutUrl.forEach(u => {
    if(`${url}`.includes(u)){
      val = false
    }
  });
  return val
}

export const isCheckout = (url: any) => {
  if(!isMobile()){
    return false
  }

  var noneCheckoutUrl = ["create", "select-a-style", "shopping-cart", "restyle"]
  var val = true
  noneCheckoutUrl.forEach(u => {
    if(`${url}`.includes(u)){
      val = false
    }
  });
  return val
}

export const isMobile = () => {
  let check = false;

  // if(typeof navigator !== "undefined"){
  //   // Disable this if you want to use the chrome browser mobile emulator
  //   if(navigator.maxTouchPoints <= 1){
  //       return check;
  //   }
  // }

  let userAgent = ""
  if(typeof navigator !== "undefined"){
    userAgent = navigator.userAgent
  }

  if(typeof navigator === "undefined")
  {
    return undefined
  }

  ((a => {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    }))(userAgent);
  return check;
}


export const getVariation = (metrics: string) => {
  let search = 'ld:64ab477c4a62a413a64095f9';
  let exclude = 'ld:64ab477c4a62a413a64095f9:$diagnostics'

  var flags: any = {};

  if (typeof window !== `undefined`){
    for ( var info in localStorage ){
      if ( info.includes(search) && !info.includes(exclude)){
        var data = localStorage.getItem( info ) || "";
        flags = JSON.parse(data)
      }
    }

    if(!flags?.[metrics]?.variation){
      return false
    } else {
      return true
    }
  }


}

export const getGiftCardNameFromType =(giftingType: number) =>{
  switch(giftingType){
    case GiftingType.ECard.id:
      return GiftingType.ECard.name
    case GiftingType.GiftBox.id:
      return GiftingType.GiftBox.name
    case GiftingType.Supplementary.id:
      return GiftingType.Supplementary.name
  }
}

export const getGiftCardPriceFromType =(giftingType: number): number =>{
  let price = 0;
  switch(giftingType){
    case GiftingType.ECard.id:
      price = GiftingType.ECard.price;
      break;
    case GiftingType.GiftBox.id:
      price = GiftingType.GiftBox.price;
      break;
    case GiftingType.Supplementary.id:
      price = GiftingType.Supplementary.price;
      break;
  }
  return parseFloat(price.toFixed(2));
}


export const getRandomBinary = (probability: number) => {
  // probability should be a value between 0 and 100
  if (probability < 0 || probability > 100) {
      throw new Error("Probability must be between 0 and 100");
  }

  // Generate a random number between 0 and 1
  const randomNumber = Math.random();

  // Compare the random number with the probability
  if (randomNumber < probability / 100) {
      return 1;
  } else {
      return 0;
  }
}

export const  UpperCaseArray = (input: string) => {
  const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;
  const result = input.match(wordRegex);
  return result?.map(f=>{ return f.toUpperCase(); }).join(" ");
}